<script setup>
import { ref, useSlots } from 'vue'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
    activeItemIndex: Number,
    accentColor: String,
    bgColor: String,
});

const activeItem = ref(props.activeItemIndex);

const setActiveItem = (key) => {
    if (activeItem.value == key) {
        activeItem.value = null
    } else {
        activeItem.value = key
    }
}

const slots = useSlots()

const processedItemContent = (content) => {

    if (typeof content === "string") {
        return content
    } else {
        let html = ""
        content.forEach((child) => {
            switch (child.type) {
                case "br":
                    html += "<br />"
                    break
                case "b":
                    html += "<b>"+child.children+"</b>"
                    break
                case "strong":
                    html += "<strong>"+child.children+"</strong>"
                    break
                case "Symbol(Text)":
                default:
                    html += child.children
                    break
            }
        })

        return html
    }
}
</script>

<template>
<ol class="list-none flex flex-col">
    <li v-for="(item, key) in slots.default()[0].children" class="px-10 pt-8 transition" :class="activeItem === key ? 'bg-light-gray-section rounded-xl' : ''" :style="activeItem === key && props.bgColor ? 'background-color: ' + bgColor : ''">
        <div class="flex flex-row items-center pb-8 border-b border-b-divider cursor-pointer" :class="activeItem === key ? 'text-primary' : ''" @click="setActiveItem(key)" :style="activeItem === key && props.accentColor ? 'color: ' + accentColor : ''">
            <h3 class="text-xl font-bold">{{ item.children[0].children }}</h3>
            <ChevronDownIcon class="h-8 w-8 ml-auto cursor-pointer transition" :class="activeItem === key ? 'rotate-180' : ''" />
        </div>
        <p class="pb-8 leading-relaxed transition" :class="activeItem !== key ? 'hidden' : ''" v-html="processedItemContent(item.children[1].children)" />
    </li>
</ol>
</template>