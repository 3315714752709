import { createApp } from 'vue'
import Accordion from '@/components/Accordion/Accordion.vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { CheckCircleIcon, StarIcon, ArrowLongLeftIcon, ArrowLongRightIcon, ArrowRightCircleIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon, QuestionMarkCircleIcon, MapPinIcon, XCircleIcon } from '@heroicons/vue/24/solid'
import dialogPolyfill from 'dialog-polyfill'

document.querySelectorAll('[data-component="MapPinIcon"]').forEach((el) => {
    createApp(MapPinIcon).mount(el)
})

document.querySelectorAll('[data-component="QuestionMarkCircleIcon"]').forEach((el) => {
    createApp(QuestionMarkCircleIcon).mount(el)
})

document.querySelectorAll('[data-component="CheckCircleIcon"]').forEach((el) => {
    createApp(CheckCircleIcon).mount(el)
})

document.querySelectorAll('[data-component="XCircleIcon"]').forEach((el) => {
    createApp(XCircleIcon).mount(el)
})

document.querySelectorAll('[data-component="StarIcon"]').forEach((el) => {
    createApp(StarIcon).mount(el)
})

document.querySelectorAll('[data-component="ArrowLongLeftIcon"]').forEach((el) => {
    createApp(ArrowLongLeftIcon).mount(el)
})

document.querySelectorAll('[data-component="ArrowLongRightIcon"]').forEach((el) => {
    createApp(ArrowLongRightIcon).mount(el)
})

document.querySelectorAll('[data-component="ArrowRightCircleIcon"]').forEach((el) => {
    createApp(ArrowRightCircleIcon).mount(el)
})

document.querySelectorAll('[data-component="Carousel"]').forEach((el) => {
    const app = createApp({})

    const carouselBreakpoints = {
        1280: {
            itemsToShow: 3,
        },
        768: {
            itemsToShow: 2,
        },
    };
    
    app.component('Carousel', Carousel)
    app.component('Slide', Slide)
    app.component('Navigation', Navigation)
    app.component('ChevronRightIcon', ChevronRightIcon)
    app.component('ChevronLeftIcon', ChevronLeftIcon)
    app.mount(el);
})

document.querySelectorAll('[data-component="Accordion"]').forEach((el) => {
    const app = createApp({})
    app.component('Accordion', Accordion)
    app.mount(el);
})

document.querySelectorAll('[data-component="ChevronLeftIcon"]').forEach((el) => {
    createApp(ChevronLeftIcon).mount(el)
})

document.querySelectorAll('[data-component="ChevronRightIcon"]').forEach((el) => {
    createApp(ChevronRightIcon).mount(el)
})

document.querySelectorAll('[data-component="ChevronDownIcon"]').forEach((el) => {
    createApp(ChevronDownIcon).mount(el)
})

document.querySelectorAll('[data-component="ChevronUpIcon"]').forEach((el) => {
    createApp(ChevronUpIcon).mount(el)
})

document.querySelectorAll('dialog').forEach((el) => {
    el.addEventListener('click', (e) => {
        if (!e.target.classList.contains('dialog-body') && !e.target.closest('.dialog-body')) {
            el.close()
        }
    })
})

let modal = document.getElementById('modalQuestions')
if (modal !== null) {
    dialogPolyfill.registerDialog(modal);
}
modal = document.getElementById('modalReviews')
if (modal !== null) {
    dialogPolyfill.registerDialog(modal);
}
